<template>
    <v-progress-circular
      :size="150"
      :width="7"
      color="#266764"
      indeterminate
    ></v-progress-circular>
</template>

<script>
import jwt_decode from "jwt-decode";
import { guiDomain } from '../../config/urls';
export default {
  name: "LoginRedirect",
  mounted: async function () {
    console.log("In LoginRedirect")
    localStorage.setItem('jwt', this.$route.query.jwt)
    await this.$store.dispatch('initHeaders')
    console.log('In GUI login redir...')
    const jwt = localStorage.getItem('jwt')
    console.log('jwt: ', jwt)    
    localStorage.setItem('roles', jwt_decode(jwt).roles)
    localStorage.setItem('email', jwt_decode(jwt).email)
    console.log('callback: ', localStorage.getItem('callback'))
    if(localStorage.getItem('callback') != undefined) {

      let valid_routes = [
        '/scheduleplanning',
        '/bytrade',
        '/byport',
        '/byvessel',
        '/byshipper',
        '/historical'
      ];
    

    let callback = localStorage.getItem('callback');

    if(localStorage.getItem('callback').includes(guiDomain) === false) {
      callback = guiDomain + localStorage.getItem('callback')
    }
    
    for(const route of valid_routes) {
        const regex = new RegExp(`^${guiDomain}${route}`);

        if(regex.test(callback)) {
            window.location.href = callback;
            break; // exit the loop once a match is found
        }
    }

    this.$router.push({name: 'home'})
      
    } else
      this.$router.push({name: 'home'})
  }
}
</script>
